@import '../../../assets/scss/variables';

.auth {
  height: 100vh;
  display: flex;
  position: relative;
  &-title{
    font-weight: 600;
    font-size: 24px;
    color: #202020;
    margin-bottom: 15px;
  }
  &-input{
    width: 350px;
    margin-bottom: 25px !important;
    & fieldset{
      border: 1px solid #DCDCDC;
      border-radius: 8px;
    }
    & input{
      &,
      &::placeholder{
        font-size: 14px;
        color: #898989;
      }
    }
  }
  &-back{
    & img{
      height: 100%;
    }
  }
  &-social{
    margin-top: 25px;
    &-head{
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      & div{
        width: 100%;
        height: 1px;
        background-color: #DCDCDC;
      }
      & span{
        color: #898989;
        font-size: 14px;
        display: inline-block;
        margin: 0 10px;
      }
    }
    &-body{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 360px;
    }
    &-item{
      border: 1px solid #DCDCDC;
      border-radius: 50px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 10px 10px 0;
      & img{
        max-width: 20px;
        max-height: 20px;
      }
      & span{
        font-size: 14px;
        color: #202020;
        font-weight: 600;
        margin-left: 8px;
      }
    }
  }
  &-forgotPass{
    margin-top: 25px;
    font-size: 14px;
    color: #202020;
    cursor: pointer;
  }
  & form{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    & .auth-btn{
      margin-top: 30px;
    }
  }
  &-wrapper{
    width: fit-content;
    margin: auto;
  }
  &-topBlock{
    position: absolute;
    top: 50px;
    right: 50px;
    & span{
      font-size: 14px;
      color: #202020;
      & b{
        font-weight: 600;
        color: #775DA6;
        margin-left: 3px;
        cursor: pointer;
      }
    }
  }
  &-go-back{
    width: 32px;
    height: 32px;
    background-color: #414446;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    top: -60px;
    & svg{
      fill: #fff;
      font-size: 30px;
    }
  }
  &-footer {
    display: flex;
    justify-content: center;

    & span {
      font-weight: 700;
      letter-spacing: 0.05em;

      font-size: 16px;

      color: $main-purple;

      & b {
        font-weight: 800;
        color: $main-purple;
      }
    }
  }
  &-form {
    &-header {
      &-title {
        font-size: 40px;
        font-weight: 800;
        color: $main-purple;
      }

      &-text {
        display: block;
        font-weight: 400;
        font-size: 18px;
        margin-top: 7px;
        color: #F3F4F8;
      }
    }
    & .auth-form-header{
      &-text {
        color: $main-purple;
      }
    }
  }
  &-error{
    width: 100%;
    padding: 16px 0 16px 16px;
    margin-top: 15px;
    background: #FF0000;
    border: 1px solid #FF0000;
    box-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.5);
    & span{
      color: #F6F6F6;
      font-weight: 700;
    }
  }
  &-privacy-policy{
    color: #fff;
    & > div{
      display: flex;
      align-items: center;
    }
    & svg{
      fill: $main-purple;
    }
    &-btn{
      font-size: 12px;
      cursor: pointer;
      color: $main-purple;
    }
    &-topText{
      color: $main-purple;
      font-size: 12px;
    }
  }
  &-reset{
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
}
.registration-form-file-input-label{
  padding:17px 14px;
}
.registration-form-file-input{
  display: none !important;
}

.registrationButton {
  margin-top: 20px !important;
  background-color: '#2D836D';
  box-shadow: '0px 0px 4px rgba(0, 0, 0, 0.2)';
  border-radius: '5px';
  width: 100%;
  font-weight: '800';
  color: 'F3F4F8';
}

.auth-form {
  & form {
    .MuiButton-root{
      margin-top: 16px;
    }
  }
}

.auth-form-label {
  color: $main-purple !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  margin-bottom: 5px !important;
}

@media screen and (max-width: 992px) {
  .auth{
    &-wrapper{
      max-width: 90%;
      width: 100%;
    }
    &-back{
      display: none;
    }
    &-input{
      width: 100%;
    }
    &-social{
      &-body{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .genderSelectorMenuItem {
    height: 30px !important;
    min-height:30px !important ;
    .MuiTouchRipple-root {
      height: 30px !important;
    }
  }
  .auth {
    min-height: 100vh;
    height:fit-content ;
    &-privacy-policy{
       & .MuiButtonBase-root{
        padding:0 !important
       }
      &-topText{
        font-size: 13px;
        line-height: 13px;
      }
    }
    &-form {
      &-label{
        margin-bottom: 0 !important;
      }
      & form {
        .signInPassword{
          & input{
            padding: 8px 14px !important;
          }
        }
        .MuiButton-root{
          padding:5px 5px !important;
        }
        .MuiFormControl-root {
          margin-bottom: 10px !important;
          & input{
            padding: 8px 14px !important;
          }
          .MuiInputBase-root {
            .MuiSelect-select {
              padding: 8px 14px;
            }
          }

          .MuiFormControl-root {
            .MuiInputBase-root {
              & input {
                padding: 8px 14px;
              }
            }
          }
        }
      }
      &-header {
        &-title {
          font-size: 24px;
        }
        &-text {
          font-size: 14px;
          padding-bottom: 5px;
        }
      }
    }
  }

  .registrationButton {
    margin-top: 10px !important;
  }

  .auth-form-label {
    font-size: 12px !important;
  }
  .registration-form-file-input-label{
    padding:10px 14px;
  }
}