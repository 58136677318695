@import './assets/scss/variables';
@import './assets/scss/mixins';

@font-face {
  font-family: "AirbnbCereal";
  font-weight: 700;
  src:
    local("AirbnbCereal"),
    url("assets/fonts/AirbnbCereal_W_Bd.otf") format("otf");
}

@font-face {
  font-family: "AirbnbCereal";
  font-weight: 500;
  src:
    local("AirbnbCereal"),
    url("assets/fonts/AirbnbCereal_W_Bk.otf") format("otf");
}

@font-face {
  font-family: "AirbnbCereal";
  font-weight: 300;
  src:
    local("AirbnbCereal"),
    url("assets/fonts/AirbnbCereal_W_Lt.otf") format("otf");
}

@font-face {
  font-family: "AirbnbCereal";
  font-weight: 600;
  src:
    local("AirbnbCereal"),
    url("assets/fonts/AirbnbCereal_W_Md.otf") format("otf");
}

@font-face {
  font-family: "AirbnbCereal";
  font-weight: 800;
  src:
    local("AirbnbCereal"),
    url("assets/fonts/AirbnbCereal_W_Blk.otf") format("otf");
}

@font-face {
  font-family: "AirbnbCereal";
  font-weight: 900;
  src:
    local("AirbnbCereal"),
    url("assets/fonts/AirbnbCereal_W_XBd.otf") format("otf");
}

body{
  overflow: auto !important;
}

#root {
  height: 100vh;
  overflow-y: auto;
}

.container {
  width: 97%;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
a,
span,
button,
div {
  font-family: 'AirbnbCereal', sans-serif !important;
}

button {
  cursor: pointer;
}

.Toastify * {
  z-index: 10000000;
}

.MuiPaper-root {
  -webkit-overflow-scrolling: touch
}

.modal-close-icon{
  color: #fff;
  right: 3px;
  top: 3px;
  font-size: 1.9rem !important;
  position: absolute;
  cursor: pointer;
}

.outlined-input{
  & .MuiFormLabel-root,
  & .MuiInputBase-input{
    color: #fff !important;
  }
  & .MuiOutlinedInput-notchedOutline{
    border-color: #fff !important;
  }
}

.animated-gift{
  width: 20px;
  height: 20px;
  &.gift-on-avatar{
    width: 30px;
    height: 30px;
  }
  & img{
    width: 100%;
    height: 100%;
  }
}

.MuiTooltip-popper{
  z-index: 200000000;
}

@media screen and (max-width: 992px) {
  .animated-gift{
    &.gift-on-avatar{
      width: 20px;
      height: 20px;
    }
    & img{
      width: 100%;
      height: 100%;
    }
  }
}

.roomBox {
  & .MuiPaper-root{
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: #888;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #2D836D;
    }
  }
}

.main-scrollbar::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

/* Track */
.main-scrollbar::-webkit-scrollbar-track {
  background: #888;
  border-radius: 10px;
}

/* Handle */
.main-scrollbar::-webkit-scrollbar-thumb {
  background: #8275EB;
  border-radius: 10px;
}

.MuiButton-root {
  min-width: unset !important;
}

.root-block{
  &.blue{
    & .navbar{
      background-color: #216A81;
    }
    & .room-info{
      background-color: #145569;
    }
    & .main{
      background-color: #36839B;
    }
    & .game-table-user-info{
      background-color: #145569;
    }
    & .MuiPaper-root{
      background-color: #216A81;
    }
    & .main-chat__container-web{
      background-color: #A9D1DD;
    }
  }
  &.graphite{
    & .navbar{
      background-color: #494d4e;
    }
    & .room-info{
      background-color: #6a6f71;
    }
    & .main{
      background-color: #545b5d;
    }
    & .game-table-user-info{
      background-color: #494d4e;
    }
    & .main-chat__container-web{
      background-color: #778083;
    }
  }
  &.lgreen{
    & .navbar{
      background-color: #176211;
    }
    & .room-info{
      background-color: #194E14;
    }
    & .main{
      background-color: #3D8737;
    }
    & .game-table-user-info{
      background-color: #194E14;
    }
    & .main-chat__container-web{
      background-color: #BFDABD;
    }
  }
  &.lpurple{
    & .navbar{
      background: #8275EB;
    }
    & .room-info{
      background-color: $main-purple
    }
    & .main{
      background-color: #6A5AE0;
    }
    & .game-table-user-info{
      background-color: #6A5AE0;
    }
    & .main-chat__container-web{
      background-color: #E8E5FA;
    }
  }
}

.main-chat__container{
  &-mobile{
    &.blue{
      & > div{
        background-color: #A9D1DD;
      }
    }
    &.graphite{
      & > div{
        background-color: #778083;
      }
    }
    &.lgreen{
      & > div{
        background-color: #BFDABD;
      }
    }
    &.lpurple{
      & > div{
        background-color: #E8E5FA;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  body{
    overflow: hidden;
  }
}