.main-loading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
  background-color: #f7bff1;
  & svg{
    width: 500px;
    height: 500px;
  }
  & span{
    font-size: 18px;
    text-align: center;
  }
  & img{
    width: 400px;
    margin: 15px 0;
    border-radius: 30px;
    filter: drop-shadow(0px 0px 24px #dd63d1);
  }
}